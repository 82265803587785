export { matchers } from './client-matchers.js';

			export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43')];

			export const server_loads = [];

			export const dictionary = {
	"/": [13],
	"/activation": [14,[2]],
	"/activation/confirmation": [15,[2]],
	"/admin": [16,[3]],
	"/admin/announcement": [17,[3]],
	"/admin/announcement/new": [18,[3]],
	"/admin/announcement/preview": [19,[4]],
	"/admin/announcement/update/[announcementId]": [20,[3]],
	"/admin/users": [21,[3]],
	"/email-verify/[...activationdata]": [22],
	"/platform/view-org": [24,[5]],
	"/platform/[slug]/users/onboarding": [23],
	"/purpose": [25],
	"/purpose/announcement/[announcementId]/[announcementTitleSlug]": [26,[6]],
	"/purpose/campaigns": [31,[6]],
	"/purpose/campaign": [27],
	"/purpose/campaign/[campaignHandle]": [28,[6]],
	"/purpose/campaign/[campaignHandle]/action/[actionType]/[actionHandle]": [29,[6]],
	"/purpose/campaign/[campaignHandle]/learn/[learnHandle]": [30,[6,7]],
	"/purpose/impact": [32,[6]],
	"/purpose/learn": [33,[6,8]],
	"/purpose/profile": [34,[6]],
	"/purpose/profile/edit": [35,[6]],
	"/purpose/team": [36,[6]],
	"/reset-password": [37,[9]],
	"/sign-in": [38,[10]],
	"/sign-in/password": [39,[10]],
	"/sign-in/sso": [40,[10]],
	"/sign-in/sso/confirmation": [41],
	"/unsubscribe": [42,[11]],
	"/wellness/[...subroute]": [43,[12]]
};

			export const hooks = {
				handleError: (({ error }) => { console.error(error) }),
			};